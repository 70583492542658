import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"


const Home = ({ data }) => (
  <Layout>
    <SEO />
  <section className="main">
    <figure>
      <GatsbyImage image={data.topimage.childImageSharp.gatsbyImageData} alt="" style={{ height: "100%" }} />
    </figure>
    <div className="phrase">
      <h1>未来への一歩を…<br />交通広告から</h1>
    </div>
  </section>
  <br />
  <br />
  <section className="area">
    <div className="container">
      <h2 className="bar">交通広告事業</h2>
      <p className="article">
        <span className="article-sub">交通広告について</span><br />
        交通広告とは、公共交通機関の施設への広告総称です。首都圏の鉄道利用者の７０％ほどがＪＲを利用しています。
        交通広告の強みは、反復的な接触や環境による強制的な接触。そして各購買拠点から近い箇所での刺激のよる購買意欲の刺激等があります。
        ではどのような箇所に広告展開が出来るのかというと、駅構内施設の各箇所と車両の中と外に広告掲出できます。
        駅構内の広告を簡単に説明すると、通路柱に埋め込まれているモニターに映し出すデジタルサイネージ広告やLED電球が埋め込まれているサインボード（駅看板）
        そして通路の壁面などに設置してある枠内に掲出する紙媒体のポスター広告。
        また、空いている壁面などや柱などに大型のシートを貼ったり、天井からフラッグを吊るしたり、加工ポスターを展開したりすのがSP広告となります。
        車両内については、車両内の網棚の上にポスターを展開するまど上ポスター広告。通路中央に吊るしてある中吊りポスター広告。各車両出入口の上部には車両内の動画広告トレインチャンネルや
        ドア内側から見れるステッカー広告やドア横に展開出来るドア横ポスター広告などがあります。また女性にターゲットを絞った専用車両に展開する女性専用中吊りジャック展開もあります。
        もちろんJRの主要な車両以外にも、ゆりかもめ線やりんかい線、成田空港までの主要アクセスとして定着した成田エクスプレスや長距離車両の新幹線車内にも広告媒体はあります。
        車両外で言うと、車体にシートを貼り付けて展開するラッピング広告。
        また一部の車両については、ラッピングと合わせて車両内の全ての広告をジャックするADトレインという商品やラッピング車両とホームドアシート広告を組み合わせた山手ラウンドセット
        という商品もあります!
        時期やエリア、そしてターゲット等に合わせて、大きな商品をインパクトある展開を行ったり、各広告商品を組み合わせて継続的に接触を図る展開をしたり
        様々な企画に合わせた柔軟な展開が可能となります。
        掲出の期間は、広告媒体により異なりますが、基本７日〜１ヶ月単位となっています。<br /><br />
      </p>
    </div>
  </section>
  <section className="Advertising">
    <div className="container">
      <h2 className="bar">交通広告へ出稿</h2>
      <div className="block">
        <div className="ad-detail">
          <figure>
          <GatsbyImage image={data.signage.childImageSharp.gatsbyImageData} alt="" />
          </figure>
          <h3><Link to={`/signage/`}>サイネージ</Link></h3>
          <p>Signage</p>
        </div>
        <div className="ad-detail">
          <figure>
          <GatsbyImage image={data.station.childImageSharp.gatsbyImageData} alt="" />
          </figure>
          <h3><Link to={`/station_ad/`}>駅広告</Link></h3>
          <p>Station Media</p>
        </div>
        <div className="ad-detail">
          <figure>
          <GatsbyImage image={data.event.childImageSharp.gatsbyImageData} alt="" />
          </figure>
          <h3><Link to={`/event/`}>イベント</Link></h3>
          <p>Event</p>
        </div>
      </div>
      <div className="block">
        <div className="ad-detail">
          <figure>
          <GatsbyImage image={data.poster.childImageSharp.gatsbyImageData} alt="" />
          </figure>
          <h3><Link to={`/poster/`}>ポスター</Link></h3>
          <p>Poster</p>
        </div>
        <div className="ad-detail">
          <figure>
          <GatsbyImage image={data.trainAd.childImageSharp.gatsbyImageData} alt="" />
          </figure>
          <h3><Link to={`/train_ad/`}>車内広告</Link></h3>
          <p>Train Media</p>
        </div>
        <div className="ad-detail">
          <figure>
          <GatsbyImage image={data.web.childImageSharp.gatsbyImageData} alt="" />
          </figure>
          <h3><Link to={`/web/`}>WEB広告</Link></h3>
          <p>Web Advertising</p>
        </div>
      </div>
    </div>
  </section>
  <hr />
  <section className="area">
      <div className="container">
      <h2 className="bar">新着広告</h2><br /><br />
      <div className="posts">
        {data.allContentfulBlogPost.edges.map(({ node }) => (
        <article className="post" key={node.id}>
            <Link to={`/blog/post/${node.slug}/`}>
            <figure>
                  <GatsbyImage
                    image={node.eyecatch.gatsbyImageData}
                    alt={node.eyecatch.description}
                    style={{ height: "100%" }}
                  />
            </figure>
            <h3>{node.title}</h3>
            </Link>
        </article>
        ))}
       </div>
      </div>
    </section>
  </Layout>
)
  
export const query = graphql`
  query {
    topimage: file(relativePath: {eq: "topimage.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    signage: file(relativePath: {eq: "signage.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300)
      }
    }
    station: file(relativePath: {eq: "station.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300)
      }
    }
    event: file(relativePath: {eq: "event.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300)
      }
    }
    poster: file(relativePath: {eq: "poster.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300)
      }
    }
    trainAd: file(relativePath: {eq: "trainAd.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300)
      }
    }
    web: file(relativePath: {eq: "web.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300)
      }
    }
    allContentfulBlogPost(
      sort: {fields: publishDate, order: DESC}
      skip: 0
      limit: 6
      ) {
    edges {
      node {
        title
        id
        slug
        eyecatch {
          gatsbyImageData(width: 510, layout: CONSTRAINED)
          description
        }
      }
    }
  }
}
`

export default Home